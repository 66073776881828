import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  calculateLineItemsTotal,
  getNonCommissionNonReversalLineItems,
} from './LineItemRefundMaybe';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const {
    transaction,
    isProvider,
    lineItems,
    intl,
    refundPercentage = 0,
    marketplaceCurrency,
    providerCommissionPercentage,
  } = props;

  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  const baseLineItems = getNonCommissionNonReversalLineItems(lineItems);
  const basePriceObject = calculateLineItemsTotal(baseLineItems, marketplaceCurrency);

  const basePrice = basePriceObject.amount ? parseFloat(basePriceObject.amount) : null;
  const refundAmount = refundPercentage ? (basePrice * refundPercentage) / 100 : 0;
  const withoutComissionAmount = basePrice - refundAmount;

  const providerCommissionAmount = (providerCommissionPercentage / 100) * withoutComissionAmount;
  const finalAmount = isProvider
    ? withoutComissionAmount - providerCommissionAmount
    : withoutComissionAmount;

  const finalPrice = new Money(finalAmount || 0, marketplaceCurrency);
  const formattedFinalPrice = finalPrice ? formatMoney(intl, finalPrice) : null;

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>
          {finalPrice?.amount ? formattedFinalPrice : formattedTotalPrice}
        </div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
