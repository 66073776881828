import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { InfoPopup } from '../../components';
import { LINE_ITEM_SECURITY_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemSecurityDepositPriceMaybe = props => {
  const { lineItems, intl } = props;

  const securityLineItemsItem = lineItems.find(
    item => item.code === LINE_ITEM_SECURITY_FEE && !item.reversal
  );

  return securityLineItemsItem ? (
    <div className={css.lineItem}>
      <div className={css.iteminfo}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.securityDepositFee" />{' '}
          <InfoPopup
            info={intl.formatMessage({ id: 'OrderBreakdown.securityDepositFeeExplainer' })}
          />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, securityLineItemsItem.lineTotal)}</span>
      </div>
    </div>
  ) : null;
};

LineItemSecurityDepositPriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSecurityDepositPriceMaybe;
