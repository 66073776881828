import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { timestampToDate } from './dates';

export const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(true);
        observer.unobserve(ref.current);
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const sortArrayByLabel = arr => {
  const defaultLocale = getDefaultLocale();

  const collator = new Intl.Collator(defaultLocale, { sensitivity: 'base' });

  return (
    isArrayLength(arr) &&
    arr.sort((a, b) => {
      return collator.compare(a.label, b.label);
    })
  );
};

/**
 * Display a toast notification based on the message type.
 *
 * @param {string} type The type of toast to display ('success' or 'error').
 * @param {string} message The message to display in the toast.
 * @param {Object} [options] Additional options for customizing the toast appearance and behavior.
 */
export const showToaster = (type, message, options = {}) => {
  const defaultOptions = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    ...options, // Merge any additional options passed to the function
  };

  // Display the toast based on the type
  if (type === 'success') {
    toast.success(message, defaultOptions);
  } else if (type === 'error') {
    toast.error(message, defaultOptions);
  } else {
    console.warn('showToast called with invalid type. Expected "success" or "error".');
  }
};

export const isDevMode = () => {
  return process.env.REACT_APP_MARKETPLACE_ROOT_URL === 'http://localhost:3000';
};

export const keepValidGermanChars = inputString => {
  // This regex matches all characters that aren't part of the German alphabet, numerics or spaces
  const regex = /[^a-zA-Z0-9äöüß ]/g;

  // Remove text enclosed within square brackets followed by a URL enclosed within parentheses
  const updatedString = inputString.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

  // Replace all matches with an empty string
  return updatedString.replace(regex, ' ');
};

/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return (str.charAt(0).toUpperCase() + str.slice(1)).trim();
};

// Helper export  to check if exactly one of the values is truthy and value1 is a non-empty array
export function isOneTruthyAndValidArray(value1, value2) {
  // Check if value1 is a non-empty array and value2 is falsy
  const isValue1ValidArray = Array.isArray(value1) && value1.length > 0;
  // Check if exactly one of the values is truthy
  return (isValue1ValidArray && !value2) || (!isValue1ValidArray && value2);
}

export const extractSeoCategoryData = data => {
  const title = data?.attributes?.title;
  const bannerTitle = data?.attributes?.bannerTitle;
  const description = data?.attributes?.mainDescription;
  const content = data?.attributes?.content;
  const heading = data?.attributes?.heading;
  const url = data?.attributes?.url;

  const headingImage = `${process.env.REACT_APP_STRAPI_BASE_URL}${data?.attributes?.headingImage?.data?.attributes?.url}`;
  const bannerImage = `${process.env.REACT_APP_STRAPI_BASE_URL}${data?.attributes?.bannerImage?.data?.attributes?.url}`;

  return {
    title,
    description,
    content,
    heading,
    headingImage,
    url,
    bannerTitle,
    bannerImage,
  };
};

export const getSuffixFromPricing = pricing => {
  if (!Array.isArray(pricing) || pricing.length === 0) {
    return null;
  }
  return pricing[0].name;
};

export const getValidPrice = pricing => {
  if (!Array.isArray(pricing) || pricing.length === 0) {
    return null;
  }
  return pricing.find(option => option.price !== undefined && option.price !== null);
};

export function extractLineItemsCodes(lineItems) {
  return lineItems.map(item => {
    let codeParts = item.code.split('/');
    return codeParts[1]; // Extract the part after '/'
  });
}

export const getKeysWithBaseUrl = contractFiles => {
  const baseUrl = process.env.REACT_APP_CF_URL;
  return contractFiles.map(url => `${baseUrl}/${url.key}`);
};

export const generateTimeSlots = selectedDate => {
  const currentDate = moment();
  const selectedMoment = moment(selectedDate);
  let timeSlots = [];

  if (selectedMoment.isSame(currentDate, 'day')) {
    // Selected date is today, filter out past time slots
    const currentTime = currentDate.startOf('minute');
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const slotTime = moment({ hour, minute });
        if (slotTime.isSameOrAfter(currentTime)) {
          timeSlots.push(slotTime.format('h:mm A'));
        }
      }
    }
  } else {
    // Selected date is any other day, return all possible time slots
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const slotTime = moment({ hour, minute });
        timeSlots.push(slotTime.format('h:mm A'));
      }
    }
  }

  return timeSlots;
};

export const getExtendDays = (transaction, endDate) => {
  if (!transaction?.booking?.attributes?.end || !endDate) {
    return 0;
  }
  const bookingEndDate = new Date(transaction.booking.attributes.end);
  const providedEndDate = new Date(endDate);
  const differenceInTime = providedEndDate.getTime() - bookingEndDate.getTime();
  if (differenceInTime < 0) {
    return 0;
  }

  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const getTitleFromError = error => {
  if (error && error.apiErrors && Array.isArray(error.apiErrors) && error.apiErrors.length > 0) {
    const firstError = error.apiErrors[0];
    return firstError.title;
  }
  return null;
};

export const getPricingDetails = (pricing, keys) => {
  const prices = {};

  if (Array.isArray(pricing) && pricing.length > 0) {
    pricing.forEach(p => {
      if (keys.includes(p.key)) {
        prices[p.key] = p;
      }
    });
  }

  return prices;
};

export function getFilteredLineItems(transaction) {
  const { lineItems } = transaction.attributes;
  const filteredItems = lineItems.filter(
    item =>
      item.code !== 'line-item/night' &&
      item.code !== 'line-item/security-deposit' &&
      item.code !== 'line-item/provider-commission' &&
      item.code !== 'line-item/customer-commission'
  );

  return filteredItems.map(item => {
    return {
      code: item.code.replace('line-item/', ''),
      amount: item.unitPrice.amount,
    };
  });
}

export const calculateTotalAmount = (allAddons = [], unitPrice) => {
  const addonsTotal = allAddons.reduce((total, addon) => total + addon.amount, 0);
  return addonsTotal + unitPrice;
};

export const extractExtentBookingDetails = transaction => {
  const extentBookingParams = transaction?.attributes?.metadata?.extentBookingParams || [];
  const transitions = transaction?.attributes?.transitions || [];

  // Filter out the "extend-booking" transitions
  const extendBookingTransitions = transitions.filter(
    transition => transition.transition === 'transition/extend-booking'
  );

  if (extendBookingTransitions.length === 0) {
    return null;
  }

  const latestExtendBooking = extendBookingTransitions[extendBookingTransitions.length - 1];

  const latestMatchedBookingDetail = extentBookingParams
    .map(detail => {
      const extendedStartUnix = detail.extendedStartUnix;
      const extendedEndUnix = detail.extendedEndUnix;
      const createdDate = detail.createdDate;
      const paymentIntent = detail?.extendBookingPaymentIntent;
      const status = detail?.status;
      const formattedStartDate = extendedStartUnix && timestampToDate(extendedStartUnix);
      const formattedEndDate = extendedEndUnix && timestampToDate(extendedEndUnix);
      const start = extendedStartUnix ? moment(extendedStartUnix).format('MMM D') : null;
      const end = extendedEndUnix ? moment(extendedEndUnix).format('MMM D') : null;

      return {
        start,
        end,
        transitionDetails: latestExtendBooking,
        createdDate,
        paymentIntent,
        status,
        formattedStartDate,
        formattedEndDate,
      };
    })
    .filter(Boolean);

  return latestMatchedBookingDetail[latestMatchedBookingDetail.length - 1] || null;
};

export const getExtendBookingDetails = transaction => {
  const extentBookingParams = transaction?.attributes?.metadata?.extentBookingParams || [];
  const transitions = transaction?.attributes?.transitions || [];

  // Filter out the "extend-booking" transitions
  const extendBookingTransitions = transitions.filter(
    transition => transition.transition === 'transition/extend-booking'
  );

  let lastEndDate = null; // To track the last end date and use it for the next start date

  // Create an array of objects containing formattedStartDate, formattedEndDate, and paymentIntent for each "extend-booking"
  const result = extendBookingTransitions.map((transition, index) => {
    const extentBookingDetails = extentBookingParams[index] || {};
    const extendedStartUnix = extentBookingDetails.extendedStartUnix;
    const extendedEndUnix = extentBookingDetails.extendedEndUnix;
    const unitPrice = extentBookingDetails.unitPrice;
    const totalAmount = extentBookingDetails.totalAmount;
    const status = extentBookingDetails?.status;
    const commission = extentBookingDetails?.providerCommission?.percentage;
    const providerCommission = extentBookingDetails?.providerCommission;

    // If there is a lastEndDate, use it for the new start date; otherwise, use the current extendedStartUnix
    const start = lastEndDate
      ? moment(lastEndDate).format('MMM D')
      : extendedStartUnix
      ? moment(extendedStartUnix).format('MMM D')
      : null;
    const end = extendedEndUnix ? moment(extendedEndUnix).format('MMM D') : null;

    // Update lastEndDate to the current extendedEndUnix only if the status is 'accepted' or 'requested'
    if (status === 'accepted' || status === 'requested') {
      lastEndDate = extendedEndUnix ? moment(extendedEndUnix) : null;
    }

    // Set newStartDate only if the status is 'accepted' or 'requested'
    const newStartDate =
      (status === 'accepted' || status === 'requested') && end
        ? moment(extendedEndUnix).format('MMM D')
        : null;
    const createdDate = extentBookingDetails.createdDate;

    return {
      start,
      end,
      transitionDetails: transition,
      createdDate,
      status,
      unitPrice,
      totalAmount,
      newStartDate,
      commission: 3.1, //Would need to refactor
      providerCommission,
    };
  });

  return result;
};

export const getAllAddonsTotal = bookingDetails => {
  return bookingDetails?.reduce((total, booking) => {
    if (booking.status !== 'declined') {
      return total + booking.totalAmount;
    }
    return total;
  }, 0);
};

export function extractProviderCommissionPercentage(response) {
  if (response?.data?.data?.length > 0) {
    const providerCommission = response.data.data[0]?.attributes?.data?.providerCommission;
    return providerCommission?.percentage ?? 0;
  }
  return 0;
}

export function calculateTotalCommission(bookingDetails, providerCommission) {
  const totalCommission = Array.isArray(bookingDetails)
    ? bookingDetails.reduce((sum, booking) => {
        if (booking.status !== 'declined') {
          return sum + (booking.commission || 0);
        }
        return sum;
      }, 0)
    : 0;

  // Return the total commission including providerCommission
  return totalCommission + (providerCommission || 0);
}

export function checkAvailableSlots(start, endDate, timeSlotsOnSelectedMonth = []) {
  // If either start or end date is not provided, return "no dates"
  if (!start || !endDate) {
    return 'no dates';
  }

  // Parse start and end dates to moment objects (full days)
  const startMoment = moment(start).add(1, 'day');
  const endMoment = moment(endDate).endOf('day'); // Ensure full day coverage for end date

  // Split the provided date range into separate ranges
  const result = splitDateRanges(startMoment, endMoment);
  // Ensure both start and end of every range in result are strictly within an available slot
  const isAvailable = result.every(range => {
    const rangeStart = moment(range.start).startOf('day');
    const rangeEnd = moment(range.end).endOf('day');

    // Check if the rangeStart and rangeEnd fall strictly within one of the slots
    const matchingSlot = timeSlotsOnSelectedMonth.some(slot => {
      const slotStart = moment(slot.attributes.start).startOf('day');
      const slotEnd = moment(slot.attributes.end).endOf('day');

      // The selected range must start after or at the start of the slot
      const isStartWithinSlot = rangeStart.isSameOrAfter(slotStart);
      // The selected range must end strictly before the end of the slot
      const isEndWithinSlot = rangeEnd.isSameOrBefore(slotEnd);

      // Ensure both conditions are true
      return isStartWithinSlot && isEndWithinSlot;
    });

    // If there's no matching slot that covers the entire range, return false
    if (!matchingSlot) {
      return false;
    }

    return true; // Slot fully covers the range
  });

  return isAvailable ? true : false;
}

export function mergeAllTimeSlots(monthlyTimeSlots) {
  let mergedTimeSlots = [];

  // Loop through each month in the monthlyTimeSlots object
  for (const month in monthlyTimeSlots) {
    // Check if the month has timeSlots and it's an array
    if (Array.isArray(monthlyTimeSlots[month].timeSlots)) {
      // Concatenate the timeSlots array into the mergedTimeSlots array
      mergedTimeSlots = mergedTimeSlots.concat(monthlyTimeSlots[month].timeSlots);
    }
  }

  return mergedTimeSlots;
}

function splitDateRanges(startMoment, endMoment) {
  // Check if the months are different
  if (moment(startMoment).month() !== moment(endMoment).month()) {
    const startOfNextMonth = moment(startMoment)
      .add(1, 'months')
      .startOf('month');
    const endOfCurrentMonth = startOfNextMonth
      .clone()
      .subtract(1, 'days')
      .endOf('day');

    return [
      {
        start: moment(startMoment).format('YYYY-MM-DDTHH:mm:ssZ'),
        end: endOfCurrentMonth.format('YYYY-MM-DDTHH:mm:ssZ'),
      },
      {
        start: startOfNextMonth.format('YYYY-MM-DDTHH:mm:ssZ'),
        end: moment(endMoment).format('YYYY-MM-DDTHH:mm:ssZ'),
      },
    ];
  } else {
    // If in the same month, return the original range
    return [
      {
        start: moment(startMoment).format('YYYY-MM-DDTHH:mm:ssZ'),
        end: moment(endMoment).format('YYYY-MM-DDTHH:mm:ssZ'),
      },
    ];
  }
}

export const convertPriceToFixedPrice = price => {
  if (isNaN(price) || price === null || price === undefined) {
    return '$0.00';
  }

  const formattedPrice = (price / 100).toFixed(2);
  return `$${formattedPrice}`;
};

export const calculateTotalProviderCommission = bookingDetails => {
  // Check if the array has length and perform operations
  return isArrayLength(bookingDetails)
    ? bookingDetails
        .map(detail => {
          // Convert providerCommission to dollars or default to 0 if invalid
          const commission = detail?.providerCommission ? detail.providerCommission : 0;
          return -commission; // Convert cents to dollars and add negative sign
        })
        .reduce((acc, curr) => acc + curr, 0) // Sum the remaining values
    : 0; // Return 0 if bookingDetails is empty or not an array
};